/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-indent */
import React from 'react';
import MenuProvider from '../../contexts/MenuProvider';
import '../../css/app.css';
import StateProvider from '../../contexts/StateProvider';

const Layout = ({ children, ...props}) => {
    return (
        <MenuProvider {...props}>
            <StateProvider {...props}>
                {children}
            </StateProvider>            
        </MenuProvider>
    )
};

export default Layout;