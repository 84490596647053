import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import url from 'url';
import MenuContext from './MenuContext';

const MenuProvider = ({ children }) => {
    const result = useStaticQuery(graphql`
    {
        wordpressMenusMenusItems(name: { eq: "MainMenu" }) {          
            name
            items {
                guid
                title
                url
            }
        }
    }`
    );
    const { items } = result.wordpressMenusMenusItems;
    const menuItems = items.map(item => ({
        ...item,
        slug: url.parse(item.url).pathname,
    }));
    const menu = [
        ...menuItems,
        { guid: 'contact', title: 'Contact', slug: '/contact/' },
        { guid: 'order', title: 'Order', slug: '/order/' },
    ];


    return (
        <MenuContext.Provider value={menu}>
            {children}
        </MenuContext.Provider>
    );
};

export default MenuProvider;