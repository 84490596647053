const { ApolloProvider } = require('react-apollo');
const React = require('react');
const Layout = require('./src/templates/components/Layout').default;
const client = require('./src/apollo/client').default;

exports.wrapPageElement = ({ element, props }) => (
    <Layout {...props}>{element}</Layout>
);


// Wraps the entire Gatsby app with Apollo.
exports.wrapRootElement = ({ element }) => {
    return <ApolloProvider client={client}>{element}</ApolloProvider>;
};
