import React, { useState } from 'react';
import StateContext from './StateContext';

const StateProvider = ({ children }) => {
    const [ menuOpen, setMenuOpen ] = useState(false);

    const state = {
        menuOpen,
        setMenuOpen,
    };

    return (
        <StateContext.Provider value={state}>
            {children}
        </StateContext.Provider>
    );
};

export default StateProvider;