// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cancel-js": () => import("./../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-order-js": () => import("./../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-post-preview-js": () => import("./../src/pages/post-preview.js" /* webpackChunkName: "component---src-pages-post-preview-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-layout-blog-category-js": () => import("./../src/templates/Layout/BlogCategory.js" /* webpackChunkName: "component---src-templates-layout-blog-category-js" */),
  "component---src-templates-layout-blog-holder-js": () => import("./../src/templates/Layout/BlogHolder.js" /* webpackChunkName: "component---src-templates-layout-blog-holder-js" */),
  "component---src-templates-layout-blog-page-js": () => import("./../src/templates/Layout/BlogPage.js" /* webpackChunkName: "component---src-templates-layout-blog-page-js" */),
  "component---src-templates-layout-page-js": () => import("./../src/templates/Layout/Page.js" /* webpackChunkName: "component---src-templates-layout-page-js" */)
}

